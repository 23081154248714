import { CalendarIcon } from '@heroicons/react/solid';
import { Box, Button, Typography } from '@mui/material';
import { SnackeetCardArea, SnackeetCardBody, SnackeetCardDateSection, SnackeetCardInfo, SnackeetCardLabel, SnackeetCardLogo } from 'components/styled/SnackeetCard';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { snackeet_blue, snackeet_teal, snackeet_yellow } from 'styles/colors';
const rolesColors = {
  admin: snackeet_yellow,
  owner: snackeet_teal,
  agent: snackeet_blue
};
export default function InvitationCard({
  invitation,
  invited_by,
  acceptInvitation,
  rejectInvitation
}) {
  const {
    _id,
    logo,
    name,
    role,
    invited_at,
    members_count,
    available_seats,
    license_key
  } = invitation;
  return <>
			<SnackeetCardArea>
				<SnackeetCardBody sx={{
        pointerEvents: 'none',
        gridTemplateColumns: '10% 1fr!important'
      }}>
					<SnackeetCardLogo>
						<img src={logo} />
					</SnackeetCardLogo>
					<SnackeetCardInfo>
						<Box display='flex' alignItems='center' typography='body2' columnGap={1}>
							<SnackeetCardLabel $color={rolesColors[role]}>
								<FormattedMessage id={`organization.role.${role}`} />
							</SnackeetCardLabel>
							<Box fontWeight='bold'>
								{available_seats === 'Infinity' ? <FormattedMessage values={{
                count: members_count
              }} id='organization.table_title.current_members' /> : <FormattedMessage values={{
                count: members_count,
                seats: available_seats
              }} id='organization.table_title.members' />}
							</Box>
						</Box>

						<Typography color='textPrimary' variant='h2' sx={{
            width: 'fit-content',
            fontWeight: 600,
            fontSize: '1.10rem',
            lineHeight: '1.25rem'
          }}>
							{name}
						</Typography>

						<SnackeetCardDateSection>
							<Typography variant='body2' component='div'>
								<CalendarIcon />
								<Typography variant='body2' component='label'>
									<FormattedMessage id='organization.table_title.invited_date' />
								</Typography>
								<Box fontWeight='bold'>
									<FormattedDate value={new Date(invited_at)} year='numeric' month='numeric' day='numeric' />
								</Box>
							</Typography>

							<Typography variant='body2' component='div'>
								<Typography variant='body2' component='label'>
									<FormattedMessage id='organization.table_title.invited_by' />
								</Typography>
								<Box fontWeight='bold'>{invited_by || <FormattedMessage id='common.unknown' />}</Box>
							</Typography>
						</SnackeetCardDateSection>
					</SnackeetCardInfo>
				</SnackeetCardBody>

				<Box sx={{
        flexShrink: 0,
        flexBasis: '200px',
        minHeight: 140,
        display: 'flex',
        flexDirection: 'column'
      }}>
					<Button variant='outlined' color='success' onClick={() => acceptInvitation(_id, available_seats >= members_count)} sx={{
          flex: 1,
          borderRadius: 0
        }}>
						<FormattedMessage id='button.accept' />
					</Button>
					<Button variant='outlined' color='error' onClick={() => rejectInvitation(_id)} sx={{
          flex: 1,
          borderRadius: 0
        }}>
						<FormattedMessage id='button.reject' />
					</Button>
				</Box>
			</SnackeetCardArea>
		</>;
}
InvitationCard.propTypes = {
  invitation: PropTypes.object.isRequired,
  invited_by: PropTypes.string,
  acceptInvitation: PropTypes.func,
  rejectInvitation: PropTypes.func
};